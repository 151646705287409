<script>

export default {
    
}
</script>
<script setup>
import {translate} from "../assets/translations.js";
</script>

<template>
<section class="services section" id="services">
                <h2 class="section__title">{{ translate("servicios").name }}</h2>
                <span class="section__subtitle">{{ translate("servicios").contentServicios }}</span>
                <div class="services__container container grid section__border">
                    <div class="services__card">
                        <i class="ri-code-s-slash-line"></i>

                        <h2 class="services__title">
                          {{translate("servicios").desarrolloWeb}}
                        </h2>

                        <p class="services__description">
                          {{translate("servicios").contentDesarrollo}}
                        </p>

                        <div class="services__border"></div>
                    </div>

                    <div class="services__card">
                        <i class="ri-tools-fill"></i>

                        <h2 class="services__title">
                          {{translate("servicios").mantenimiento}}
                        </h2>

                        <p class="services__description">
                            {{translate("servicios").contentMantenimiento}}
                        </p>

                        <div class="services__border"></div>
                    </div>

                    <div class="services__card">
                        <i class="ri-database-2-line"></i>

                        <h2 class="services__title">
                          {{translate("servicios").servidor}}
                        </h2>

                        <p class="services__description">
                          {{translate("servicios").contentServidor}}
                        </p>

                        <div class="services__border"></div>
                    </div>
                </div>
        </section>
</template>


<style>
</style>