<script setup>
import WebDevelopment from './views/WebDevelopment.vue'

// Función global para cambiar el idioma y recargar la página
window.changeLang = (lang) => {
  localStorage.setItem("lang", lang);
  location.reload();
}
</script>

<template>
  <WebDevelopment/>
</template>

<style>
</style>
