<script setup>
import {translate} from "../assets/translations.js";

const year = new Date().getFullYear();
</script>


<template>
<footer class="footer">
            <div class="footer__container container">
                <!--<img src="assets/img/resilogo2.png" alt="">-->
                <h1 class="footer__title">Resi Corporation</h1>
                <p>{{ translate("servicios").content }}</p>

                <ul class="footer__list">
                    <li>
                        <a href="#home" class="footer__link" aria-label="Home">{{translate("inicio").name}}</a>
                    </li>

                    <li>
                        <a href="#skills" class="footer__link" aria-label="Skills">{{translate("desarrollo").habilidades}}</a>
                    </li>

                    <li>
                        <a href="#projects" class="footer__link" aria-label="Projects">{{translate("proyectos").nameProyecto}}</a>
                    </li>
                </ul>

                <ul class="footer__social">
                    <li>
                        <a title="Instagram Resi Corporation" href="https://www.instagram.com/resicorporation/" target="_blank" rel="noopener noreferrer" class="footer__social-link" aria-label="Instagram Resi Corporation">
                            <i class="ri-instagram-line"></i>
                        </a>
                    </li>

                    <li>
                        <a title="Linkedin Máximo" href="https://www.linkedin.com/in/m%C3%A1ximo-resi-050609259/" target="_blank" rel="noopener noreferrer" class="footer__social-link">
                            <i class="ri-linkedin-box-fill"></i>
                        </a>
                    </li>
                    
                    <li>
                        <a title="Linkedin Martín" href="https://www.linkedin.com/in/martin-catalano-resi/" target="_blank" rel="noopener noreferrer" class="footer__social-link">
                            <i class="ri-linkedin-box-fill"></i>
                        </a>
                    </li>
                </ul>

                <span class="footer__copy">
                    &copy; {{year}} developed by Máximo Felipe Resi & Martín Catalano Resi. All rights reserved.
                </span>
            </div>
        </footer>
</template>


<style>
</style>