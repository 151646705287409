import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Cargar translations.js antes de montar la aplicación
import('/src/assets/translations.js')
  .then(() => {
    console.log('Translations cargadas');

    // Configurar el idioma por defecto si no está en localStorage
    let lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "es");
    }

    // Montar la aplicación después de que translations.js esté cargado
    const app = createApp(App);
    app.use(store).use(router).mount('#app');
  })
  .catch(error => {
    console.error('Error al cargar las traducciones:', error);
    // Montar la aplicación incluso si translations.js falla
    const app = createApp(App);
    app.use(store).use(router).mount('#app');
  });
