<script>
export default {
    mounted() {
        // Función para abrir y cerrar el menú de navegación
        const navToggle = document.getElementById('nav-toggle');
        const navClose = document.getElementById('nav-close');
        const navMenu = document.getElementById('nav-menu');

        if(navToggle && navClose && navMenu) {
            navToggle.addEventListener('click', () => {
                navMenu.classList.add('show-menu');
            });

            navClose.addEventListener('click', () => {
                navMenu.classList.remove('show-menu');
            });
        }

        // Función para cambiar de tema
        const themeButton = document.getElementById('theme-button');

        if(themeButton) {
            themeButton.addEventListener('click', () => {
                document.body.classList.toggle('dark-theme');
            });
        }

        // Función para manejar el evento de hacer clic en los enlaces de navegación
        const navLinks = document.querySelectorAll('.nav__link');

        navLinks.forEach(link => {
            link.addEventListener('click', () => {
                const navMenu = document.getElementById('nav-menu');
                navMenu.classList.remove('show-menu');
            });
        });

        // Cambiar el color de fondo del encabezado al hacer scroll
        const scrollHeader = () => {
            const header = document.getElementById('header');
            // Agregar una clase si el desplazamiento vertical es mayor o igual a 50
            window.scrollY >= 50 ? header.classList.add('bg-header') : header.classList.remove('bg-header');
        };
        window.addEventListener('scroll', scrollHeader);
    }
}
</script>

<script setup>
  import {computed} from "vue";
  import {translate} from "../assets/translations.js";

  const Image = computed(() => {
    if (localStorage.getItem("lang") === "es") return "/img/british_flag.png";
    return "/img/spain_flag.jpeg";
  })

  const actionLang = () => {
    let lang = localStorage.getItem("lang") === "es" ? "en" : "es";
    localStorage.clear()
    changeLang(lang);
  }
</script>

<template>
    <header class="header" id="header">
        <nav class="nav container">
            <a href="#home" class="nav__logo">
                Resi Corporation
            </a>
            <div class="nav__menu" id="nav-menu">
                <ul class="nav__list grid">
                    <li class="nav__item">
                        <a href="#home" class="nav__link active-link" aria-label="Home">
                            <i class="ri-home-office-line"></i> {{translate("inicio").name}}
                        </a>
                    </li>

                    <li class="nav__item">
                        <a href="#skills" class="nav__link" aria-label="Skills">
                            <i class="ri-star-line"></i> {{translate("desarrollo").habilidades}}
                        </a>
                    </li>

                    <!--<li class="nav__item">
                        <a href="#studies" class="nav__link">
                            <i class="ri-graduation-cap-line"></i> Estudios
                        </a>
                    </li> -->
                    
                    <li class="nav__item">
                        <a href="#services" class="nav__link" aria-label="Services">
                            <i class="ri-briefcase-line"></i> {{ translate("servicios").name }}
                        </a>
                    </li>

                    <li class="nav__item">
                        <a href="#projects" class="nav__link" aria-label="Projects">
                            <i class="ri-pages-line"></i> {{translate("proyectos").nameProyecto}}
                        </a>
                    </li>

                    <li class="nav__item">
                        <a href="#contact" class="nav__link" aria-label="Contact">
                            <i class="ri-chat-2-line"></i> {{translate("contacto").name}}
                        </a>
                    </li>
                </ul>

                <div class="nav__close" id="nav-close">
                    <i class="ri-close-line"></i>
                </div>
            </div>
            <div class="nav__buttons">
                <div id="flag" @click="actionLang">
                  <img :src="Image" alt="Cambio de idioma" title="Cambio de idioma">
                </div>
                <i class="ri-moon-line change-theme" id="theme-button"></i>
                <div class="nav__toggle" id="nav-toggle">
                    <i class="ri-menu-4-line"></i>
                </div>
            </div>
        </nav>
    </header>
</template>


<style scoped>
#flag {
  width: 20px;
  height: 20px;

  border-radius: 100%;

  cursor: pointer
;

  &>img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}
</style>
